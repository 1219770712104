import React, { createContext, useContext, useState } from 'react'
import { Badge, Button, Fade, ToastContainer } from 'react-bootstrap'
import { useNotifications } from '../hooks/webSockets/useNotifications'
import { Notification } from '../components/Notification'
import { I18nContext } from './I18nProvider'
import { ReactComponent as CheckCircle } from 'bootstrap-icons/icons/check-circle.svg'

export const NotificationContext = createContext<{
  unreadNotificationsCount: number
  decrementUnreadUCount: () => void
  showSavedAlert: () => void
}>({
  unreadNotificationsCount: 0,
  decrementUnreadUCount: () => {},
  showSavedAlert: () => {}
})
NotificationContext.displayName = 'NotificationContext'

export const NotificationProvider = ({ children }) => {
  const { translate } = useContext(I18nContext)
  const [showShowSavedAlert, setShowShowSavedAlert] = useState(false)
  const { notifications, setNotifications, unreadNotificationsCount, decrementUnreadUCount } = useNotifications()

  const showSavedAlert = () =>
    setTimeout(() => {
      if (!showShowSavedAlert) {
        setShowShowSavedAlert(true)
        setTimeout(() => setShowShowSavedAlert(false), 1500)
      }
    }, 300)

  const showCloseAll = notifications.length > 1
  return (
    <NotificationContext.Provider value={{ unreadNotificationsCount, decrementUnreadUCount, showSavedAlert }}>
      {children}
      <Fade in={showShowSavedAlert} mountOnEnter unmountOnExit>
        <h2 className="position-fixed top-0 start-50 translate-middle-x adv-z-index-6 mt-1 adv-font-1_6em">
          <Badge bg="success" pill>
            <span className="fw-light">{translate('notifications.saved')}</span>
            <CheckCircle width="20" height="20" fill="white" className="ms-2" />
          </Badge>
        </h2>
      </Fade>
      <ToastContainer
        className={`d-flex flex-column align-items-end position-fixed top-0 ${
          showCloseAll ? 'pt-4' : 'adv-pt-4rem'
        } pe-2 pb-4 w-100 vh-100 overflow-auto`}
        style={{ zIndex: 999 }}
      >
        <Button
          variant="link"
          className="pe-auto text-dark fw-semibold lh-lg pb-0 pt-2 border-0"
          onClick={() => setNotifications([])}
          hidden={!showCloseAll}
        >
          {translate('notifications.closeAll')}
        </Button>
        {notifications?.map((notification, index) => (
          <Notification
            key={notification?.notificationId || notification.text}
            notification={notification}
            onClose={() => setNotifications(notifications.filter((_, i) => i !== index))}
            className="adv-max-vw-95"
          />
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  )
}
