import React, { Fragment, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Button, Col, Container, Image, Nav, Navbar, Offcanvas, Row, Spinner, Stack } from 'react-bootstrap'
import { ReactComponent as BadgeAd } from 'bootstrap-icons/icons/badge-ad.svg'
import { ReactComponent as Bell } from 'bootstrap-icons/icons/bell.svg'
import { ReactComponent as ChevronLeft } from 'bootstrap-icons/icons/chevron-left.svg'
import { ReactComponent as DoorOpen } from 'bootstrap-icons/icons/door-open.svg'
import { ReactComponent as Book } from 'bootstrap-icons/icons/book.svg'
import { ReactComponent as Gear } from 'bootstrap-icons/icons/gear.svg'
import { ReactComponent as HouseDoor } from 'bootstrap-icons/icons/house-door.svg'
import { ReactComponent as PersonCircle } from 'bootstrap-icons/icons/person-circle.svg'
import { ReactComponent as WindowStack } from 'bootstrap-icons/icons/window-stack.svg'
import { useNavigate } from 'react-router-dom'
import { I18nContext } from '../providers/I18nProvider'
import { AuthContext } from '../providers/AuthProvider'
import { SubscriptionContext } from '../providers/SubscriptionProvider'
import { NotificationContext } from '../providers/NotificationProvider'
import { BalanceContext } from '../providers/BalanceProvider'
import { I18nLanguageSelect } from './I18nLanguageSelect'
import { CookiePolicy } from './CookiePolicy'
import { LoadingSpinner } from './LoadingSpinner'
import { currencyToSymbol } from '../util/currencyToSymbol'
import { ButtonVariant } from 'react-bootstrap/types'
import { goTo } from '../util/goTo'
import { AppConfigContext } from '../providers/AppConfigProvider'
import { SystemMenu } from './SystemMenu'
import { useBrowserNavigationWrapper } from '../hooks/rest/useBrowserNavigationWrapper'
import { PhoneRequestDialog } from './PhoneRequestDialog'
import { CookieNames } from '../types/cookieNames'

export type ActionProps = {
  variant?: ButtonVariant
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  text?: string | ReactNode
}

export type PageProps = {
  show?: boolean
  children?: ReactNode
  header?: string | React.FC
  goBack?: (onCancel?: () => void) => void
  useDefaultGoBack?: boolean
  action1?: ActionProps
  action2?: ActionProps
  containerClasses?: string
  bodyClasses?: string
  scrollToId?: string
}

const scrollDelay = 500

export const Page: React.FC<PageProps> = ({
  show = true,
  children,
  header: Header = '',
  goBack,
  useDefaultGoBack = false,
  action1,
  action2,
  containerClasses = '',
  bodyClasses = 'p-3',
  scrollToId
}: PageProps) => {
  const navigate = useNavigate()
  const toggleButtonRef = useRef<HTMLButtonElement>()
  const { translate } = useContext(I18nContext)
  const { paymentCurrency, siteEditorEnabled, knowledgeBaseUrl } = useContext(AppConfigContext)
  const { balances } = useContext(BalanceContext)
  const {
    user: { name, pictureUrl },
    logout
  } = useContext(AuthContext)
  const { currentSubscription, refreshActive } = useContext(SubscriptionContext)
  const { unreadNotificationsCount } = useContext(NotificationContext)
  const isAdvModule = window.location.pathname.startsWith('/adv')

  const defaultGoBack = () => {
    const referrer = document.referrer && new URL(document.referrer)
    try {
      if (
        window.history.state?.idx > 0 || //not first page in history
        (referrer && referrer.origin === window.location.origin && !referrer.pathname.startsWith('/login')) //user come from different module but not login page
      ) {
        navigate(-2) //-2 is because every page push its copy to the history to handle browser back event
      } else navigate('/dashboard')
    } catch {
      navigate('/dashboard')
    }
  }
  const onBack = useDefaultGoBack ? defaultGoBack : goBack
  useBrowserNavigationWrapper(onBack)

  const balanceValue = useMemo(
    () =>
      balances?.length ? balances.find(b => b.currency === paymentCurrency)?.balanceValue?.toFixed(2) || '0' : '0',
    [balances]
  )

  useEffect(() => {
    if (scrollToId) {
      setTimeout(() => {
        document.getElementById(scrollToId)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, scrollDelay)
    } else {
      window.scrollTo(0, 0)
    }
  }, [scrollToId])

  const onNavLinkClick = (link: string) => {
    isAdvModule ? navigate(link) : goTo({ pathname: `/adv${link}` })
  }

  return show ? (
    <div id="adv-page">
      <Row className="w-100 m-0 p-0 min-vh-100">
        <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-end adv-z-index-1046" />
        <Col sm={12} md={8} lg={6} xl={6} xxl={4} className="p-0">
          <div className={'min-vh-100 d-flex flex-column justify-content-between'}>
            <header className="position-fixed start-0 top-0 w-100 adv-bg adv-z-index-6">
              <Row className="w-100 m-0 p-0">
                <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-end" />
                <Col sm={12} md={8} lg={6} xl={6} xxl={4} className="p-0">
                  <Navbar expand={false} className="adv-bg">
                    <Container fluid>
                      <Stack direction="horizontal" className="w-100">
                        <Navbar.Brand onClick={() => (onBack ? onBack() : {})} className="m-0 p-0">
                          <div hidden={!onBack} role="button">
                            <ChevronLeft fill="royalblue" width="20" height="20" className="pb-1" />
                            <small className="text-primary">{translate('header.back')}</small>
                          </div>
                          <h2 hidden={!!onBack} className="my-0 text-wrap">
                            {typeof Header === 'string' ? Header : <Header />}
                          </h2>
                        </Navbar.Brand>
                        <Navbar.Toggle
                          ref={toggleButtonRef}
                          className="ms-auto border-0 p-0 align-self-start"
                          onClick={() => refreshActive()}
                        />
                        <Badge
                          pill
                          hidden={unreadNotificationsCount === 0}
                          bg="danger"
                          className="position-absolute top-0 end-0 m-1"
                          onClick={() => toggleButtonRef.current.click()}
                        >
                          {unreadNotificationsCount}
                        </Badge>
                      </Stack>
                      <Navbar.Offcanvas placement="end">
                        <Offcanvas.Header closeButton>
                          <Stack>
                            <Image
                              height={50}
                              width={50}
                              src={pictureUrl}
                              className="rounded-circle"
                              hidden={!pictureUrl}
                            />
                            <div hidden={!!pictureUrl}>
                              <PersonCircle width="50" height="50" />
                            </div>
                            <h6 className="my-2 mt-1">{name}</h6>
                            <div
                              onClick={() => onNavLinkClick('/subscription')}
                              hidden={!!currentSubscription}
                              className="adv-link m-0"
                            >
                              <strong>{translate('subscription.no')}</strong>
                            </div>
                            <p
                              className="text-secondary m-0"
                              hidden={!currentSubscription?.subscriptionReference?.subscriptionId}
                            >
                              <strong>
                                {translate('subscription.plan')}:&nbsp;
                                <span className="adv-link" onClick={() => onNavLinkClick('/subscription')}>
                                  {translate(
                                    `subscription.${currentSubscription?.subscriptionReference?.subscriptionId}.name`
                                  )}
                                </span>
                              </strong>
                            </p>
                            <p className="text-secondary mb-2" hidden={!currentSubscription?.finishedOn}>
                              <small>
                                {translate('subscription.finishedOn')}: {currentSubscription?.finishedOn}
                              </small>
                            </p>
                            <div className="adv-link" onClick={() => onNavLinkClick('/balance')}>
                              <small className="fw-bold fst-italic">
                                {translate('withCurrency', {
                                  sign: '',
                                  currency: currencyToSymbol(paymentCurrency),
                                  number: balanceValue
                                })}
                              </small>
                            </div>
                          </Stack>
                        </Offcanvas.Header>

                        <Offcanvas.Body>
                          <Nav className="h-100">
                            <Nav.Link
                              onClick={() => (isAdvModule ? navigate('/dashboard') : navigate('/'))}
                              className="text-nowrap"
                            >
                              <HouseDoor width="20" height="20" className="me-2" />
                              {translate('header.dashboard')}
                            </Nav.Link>
                            <hr className="my-2" />
                            <Nav.Link onClick={() => onNavLinkClick('/notifications')} className="text-nowrap">
                              <Bell width="20" height="20" className="me-2" />
                              {translate('header.notifications')}
                              <Badge pill hidden={unreadNotificationsCount === 0} bg="danger" className="ms-1">
                                {unreadNotificationsCount}
                              </Badge>
                            </Nav.Link>
                            <hr className="my-2" />
                            {siteEditorEnabled && (
                              <div>
                                <Nav.Link
                                  onClick={() => goTo({ pathname: isAdvModule ? '/site-editor' : '/adv' })}
                                  className="text-nowrap"
                                >
                                  {isAdvModule ? (
                                    <WindowStack width="20" height="20" className="me-2" />
                                  ) : (
                                    <BadgeAd width="20" height="20" className="me-2" />
                                  )}
                                  {isAdvModule ? translate('header.siteEditor') : translate('header.adverton')}
                                </Nav.Link>
                                <hr className="my-2" />
                              </div>
                            )}

                            <Nav.Link
                              onClick={() =>
                                goTo({
                                  origin: knowledgeBaseUrl,
                                  newTab: true
                                })
                              }
                              className="text-nowrap"
                            >
                              <Book width="20" height="20" className="me-2" />
                              {translate('header.knowledgeBase')}
                            </Nav.Link>
                            <hr className="my-2" />
                            <Nav.Link onClick={() => onNavLinkClick('/settings')} className="text-nowrap">
                              <Gear width="20" height="20" className="me-2" />
                              {translate('header.settings')}
                            </Nav.Link>
                            <hr className="my-2" />
                            <I18nLanguageSelect />
                            <Nav.Link onClick={() => logout()} className="text-nowrap mt-auto">
                              <DoorOpen width="20" height="20" className="me-2" />
                              {translate('header.logout')}
                            </Nav.Link>
                          </Nav>
                        </Offcanvas.Body>
                      </Navbar.Offcanvas>
                    </Container>
                  </Navbar>
                </Col>
                <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white" />
              </Row>
            </header>

            <main role="main" className={`flex-fill px-0 m-0 w-100 ${containerClasses}`}>
              <h2 hidden={!onBack} className="px-3 pb-3 pt-5 m-0 w-100 adv-bg">
                {typeof Header === 'string' ? Header : ''}
              </h2>
              <div className={bodyClasses}>{children ?? <Fragment />}</div>
              <CookiePolicy />
              <PhoneRequestDialog show={document.cookie.includes(`${CookieNames.ASK_PHONE_NUMBER}=true`)} />
            </main>

            <footer className="sticky-bottom adv-bg adv-z-index-6">
              <div className="py-2 px-3" hidden={!action1 && !action2}>
                <Row className="adv-row-gutter-05rem">
                  <Col xs={!!action1 && !!action2 ? 6 : 12} sm={!!action1 && !!action2 ? 6 : 12} hidden={!action1}>
                    <Button
                      className="w-100 h-100 px-0 px-sm-3 text-break"
                      variant={action1?.variant || 'primary'}
                      onClick={action1?.onClick}
                      disabled={action1?.disabled || action1?.loading}
                    >
                      {action1?.text}
                      {action1?.loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </Col>
                  <Col xs={!!action1 && !!action2 ? 6 : 12} sm={!!action1 && !!action2 ? 6 : 12} hidden={!action2}>
                    <Button
                      className="w-100 h-100 px-0 px-sm-3 text-break"
                      variant={action2?.variant || 'primary'}
                      onClick={action2?.onClick}
                      disabled={action2?.disabled || action2?.loading}
                    >
                      {action2?.text}
                      {action2?.loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>

              <SystemMenu siteEditorEnabled={siteEditorEnabled} />
            </footer>
          </div>
        </Col>
        <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-start adv-z-index-1046" />
      </Row>
    </div>
  ) : (
    <LoadingSpinner />
  )
}
